import { captureException } from '@sentry/react';
import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import getDesignData from 'editor/src/store/design/selector/getDesignData';
import { DesignData } from 'editor/src/store/design/types';
import requestPreviewsOperation from 'editor/src/store/editorModules/preview/operation/requestPreviewsOperation';
import resetSpreadPreviewOperation from 'editor/src/store/editorModules/preview/operation/resetSpreadPreviewOperation';
import getAdvancedFlatPreviews from 'editor/src/store/editorModules/preview/selector/getAdvancedFlatPreviews';
import getPreviewStatus from 'editor/src/store/editorModules/preview/selector/getPreviewStatus';
import { FlatPreview, PreviewStatus, PreviewType } from 'editor/src/store/editorModules/preview/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import { PluginName, PluginState } from 'editor/src/store/plugins/types';
import { ControlUIType, ProductControlOptionColor } from 'editor/src/store/variants/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import Button from 'editor/src/component/Button';
import ColorElement from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/elements/ColorElement';
import IconArrowRight from 'editor/src/component/Icon/IconArrowRight';
import IconPremium from 'editor/src/component/Icon/IconPremium';
import IconPremiumSilver from 'editor/src/component/Icon/IconPremiumSilver';
import Carousel from 'editor/src/component/Preview/FlatPreview/Carousel';
import ListItem from 'editor/src/component/Preview/FlatPreview/FlatPreviewList/ListItem';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

interface PreviewGroupItem {
  productUid: string;
  designData: DesignData;
  controlOption: ProductControlOptionColor | undefined;
  previews: FlatPreview[];
}

interface Props {
  closePreview: () => void;
}

function FlatPreviewAdvanced({ closePreview }: Props) {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeGroupIndex, setActiveGroupIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const { advancedFlatPreviews, status, variationGroups, controls } = useSelector(
    (state) => ({
      advancedFlatPreviews: getAdvancedFlatPreviews(state),
      status: getPreviewStatus(state),
      designStructure: state.design.designData,
      variationGroups: state.variants.variationGroups,
      controls: state.variants.product.productControls,
    }),
    shallowEqual,
  );

  const currentDesignData = useSelector(getDesignData);
  const hostActions = useSelector((state) => getHostSetting(state, 'hostActions'));
  const nextStepAction = useMemo(() => {
    return hostActions?.nextStep;
  }, [hostActions]);

  // tmp solution to compare filters as we don't receive information about mockup from the host
  const plugin = useSelector((state) => getPlugin(state, PluginName.Filters));
  const hasPremium = plugin?.state === PluginState.ENABLED;
  const colorControl = controls.find((control) => control.uiType === ControlUIType.Color);

  const groupItems = useMemo<PreviewGroupItem[]>(() => {
    // use current design structure if there is only one group
    if (variationGroups.length <= 1) {
      const variationGroup = variationGroups?.[0];
      const variant = variationGroup ? variationGroup.variationsInfo[0] : undefined;

      const designData = currentDesignData;
      const productUid = designData?.product_uid;

      if (!designData || !productUid) {
        captureException(new Error('Could not find design data for variant'), {
          extra: {
            productUid,
            variant,
          },
        });
        return [];
      }

      return [
        {
          productUid,
          designData,
          // we don't care about variant color if there is only 1 variant
          controlOption: undefined,
          previews: Object.values(advancedFlatPreviews)[0],
        },
      ];
    }

    const controlOptionsByValues = (colorControl?.options || []).reduce<{
      [controlValue: string]: ProductControlOptionColor;
    }>((result, option) => {
      result[option.value] = option as ProductControlOptionColor;
      return result;
    }, {});

    return variationGroups
      .map((variationGroup) => {
        const variant = variationGroup.variationsInfo[0];
        let productUid: string;
        let designData: DesignData | undefined;

        if (variationGroup.variationsInfo[0]?.designData) {
          designData = variationGroup.variationsInfo[0].designData;
          productUid = designData.product_uid;
        } else {
          productUid = variationGroup.variationsInfo[0].variation.productUid;
          if (currentDesignData?.product_uid === productUid) {
            designData = currentDesignData;
          }
        }

        if (!designData || !productUid) {
          return undefined;
        }

        return {
          productUid,
          designData,
          controlOption: colorControl?.key ? controlOptionsByValues[variant.variation[colorControl.key]] : undefined,
          previews: advancedFlatPreviews[productUid],
        };
      })
      .filter((groupItem) => groupItem) as PreviewGroupItem[];
  }, [variationGroups, controls, advancedFlatPreviews, currentDesignData?.product_uid]);

  useEffect(() => {
    return () => {
      dispatch(resetSpreadPreviewOperation());
    };
  }, []);

  useEffect(() => {
    if (status !== PreviewStatus.LOADED) {
      return;
    }

    // previews exists - everything's fine
    if (groupItems[activeGroupIndex]?.previews) {
      return;
    }

    // TODO find a better way to avoid empty screen
    const groupIndexWithPreviews = groupItems?.findIndex((groupItem) => !!groupItem?.previews?.length);
    if (groupIndexWithPreviews === -1) {
      captureException(new Error('Preview have not been found'), {
        extra: {
          groupItems,
          advancedFlatPreviews,
          variationGroups,
        },
      });
      closePreview();
      return;
    }

    setActiveGroupIndex(groupIndexWithPreviews);
  }, [activeGroupIndex, groupItems, status]);

  const items = useMemo(() => {
    const storeItems = groupItems[activeGroupIndex]?.previews || [];
    const noPreviewsAvailable = !storeItems.length;
    if (noPreviewsAvailable) {
      return [];
    }

    return status === PreviewStatus.LOADED ? storeItems : [{ url: '', name: '' }];
  }, [groupItems, status, activeGroupIndex]);

  const selectItem = useCallback(
    (index: number) => {
      const previewItem = groupItems[activeGroupIndex].previews[index];
      if (!previewItem.isPremium || hasPremium) {
        setActiveIndex(index);
      }
    },
    [activeGroupIndex, groupItems],
  );

  const selectNextAvailableItem = (index: number) => {
    setActiveIndex((previousValue: number) => {
      const previews = groupItems[activeGroupIndex]?.previews || [];
      const totalItems = previews.length;

      if (totalItems === 0) {
        return previousValue;
      }

      let nextAvailableIndex;
      let i = index;
      let iterations = 0; // Prevent infinite looping

      const isMovingForward = previousValue < index || (previousValue === 0 && index === totalItems - 1);

      while (nextAvailableIndex === undefined && iterations < totalItems) {
        const previewItem = previews[i];

        if (!previewItem.isPremium || hasPremium) {
          nextAvailableIndex = i;
          break;
        }

        i = isMovingForward ? (i + 1) % totalItems : (i - 1 + totalItems) % totalItems;
        iterations += 1;
      }

      return nextAvailableIndex !== undefined ? nextAvailableIndex : previousValue;
    });
  };

  const switchGroup = (groupIndex: number) => {
    if (groupIndex === activeGroupIndex) {
      return;
    }

    const newGroup = groupItems[groupIndex];
    if (!newGroup.previews?.length) {
      dispatch(requestPreviewsOperation(PreviewType.PREVIEW_FLAT_ADVANCED, newGroup.designData));
    }

    setActiveGroupIndex(groupIndex);
  };

  const colorOptions = useMemo(() => {
    return groupItems
      .map((groupItem) => groupItem.controlOption)
      .filter((option): option is ProductControlOptionColor => !!option);
  }, [groupItems]);

  const onNextStep = useCallback(() => {
    sendPostMessage('editor.hostActionTriggered', {
      actionId: 'nextStep',
    });
    closePreview();
  }, []);

  if (items.length === 0) {
    return null;
  }

  return (
    <div
      className={cn(styles.FlatPreviewAdvanced, 'cy-flat-preview-advanced', {
        [styles.mobile]: isMobile,
      })}
    >
      <div className={styles.carouselContainer}>
        <Carousel
          items={items}
          isMobile={isMobile}
          activeIndex={activeIndex}
          setActiveIndex={selectNextAvailableItem}
        />
      </div>
      <div className={styles.controlsContainer}>
        <div className={styles.controlsTitle}>{t('Select how to preview your product:')}</div>
        {colorOptions.length > 1 && (
          <div className={cn(styles.groupFilters, 'cy-preview-color-controls')}>
            <div className={styles.groupFiltersTitle}>{t('Color')}</div>
            <div>
              {!!colorControl &&
                colorOptions.map((colorOption, idx) => (
                  <ColorElement
                    key={colorOption.value}
                    option={colorOption}
                    selected={idx === activeGroupIndex}
                    toggle={() => switchGroup(idx)}
                    control={colorControl}
                    unavailable={undefined}
                    disabled={false}
                  />
                ))}
            </div>
          </div>
        )}
        <div className={cn(styles.groupFilters, styles.listItemsSection)}>
          <div className={styles.groupFiltersTitle}>{t('Mockup style')}</div>
          <div className={styles.imageThumbnailsContainer}>
            {groupItems[activeGroupIndex]?.previews.map((preview, idx) => {
              const Item = (
                <div key={preview.name} className={styles.imageThumbnail}>
                  <ListItem
                    index={idx}
                    className={styles.imageThumbnailContent}
                    loaderClassName={styles.imageThumbnailLoader}
                    item={preview}
                    active={idx === activeIndex}
                    disabled={preview.isPremium && !hasPremium}
                    onSelect={selectItem}
                  >
                    {!!preview.isPremium && hasPremium && (
                      <div className={cn(styles.premiumIconContainer)}>
                        <IconPremiumSilver />
                      </div>
                    )}
                    {!!preview.isPremium && !hasPremium && (
                      <div className={cn(styles.premiumIconContainer)}>
                        <IconPremium />
                      </div>
                    )}
                  </ListItem>
                </div>
              );
              if (preview.isPremium && !hasPremium) {
                return (
                  <WithTooltip key={preview.name} overlay={t('Available with Gelato+')} tappable>
                    {Item}
                  </WithTooltip>
                );
              }

              // eslint-disable-next-line react/jsx-no-useless-fragment
              return <div key={preview.name}>{Item}</div>;
            })}
          </div>
        </div>
        <div className={styles.bottomActions}>
          {!!nextStepAction?.enabled && (
            <Button className="cy-button-continue" primary stopPropagation onClick={onNextStep}>
              {nextStepAction.title || t('Continue')}
              <IconArrowRight className="ml-1" />
            </Button>
          )}
          <Button className="cy-button-close" secondary stopPropagation onClick={closePreview}>
            {t('Keep editing')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default React.memo(FlatPreviewAdvanced);
